import request from './base'

export const login = (username, password) => {
  return request({
    url: '/login',
    method: 'POST',
    data: {
      username, password
    }
  })
}

export const signup = (data) => {
  return request({
    url: '/createUser',
    method: 'POST',
    data,
  })
}

export const loadData = userId => {
  return request({
    url: `/users/${userId}`,
  })
}

export const linkPayment = () => {
  return request({
    url: `/users/link_payment`,
    method: 'POST'
  })
}

export const updateProfile = (userId, data) => {
  return request({
    url: `/users/${userId}`,
    method: 'PUT',
    data
  })
}

