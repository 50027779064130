import request from './base'

export const createProduct = ({
  name,
  price,
  image,
  category,
  subcategory,
  store,
  sku,
  myproduct,
  unit,
  unitValue,
  status,
}) => {
  return request({
    url: '/products',
    method: 'POST',
    data: {
      name,
      price,
      image,
      category,
      subcategory,
      store,
      sku,
      myproduct,
      unit,
      unitValue,
      status,
    }
  })
}

export const searchProducts = keyword => {
  return request({
    url: '/products/search',
    method: 'POST',
    data: { keyword }
  })
}

export const getProducts = (filter, page, sort, limit) => {
  return request({
    url: '/products', params: {
      filter,
      page,
      sort,
      limit,
    }
  })
}

export const updateProduct = (productId, data) => {
  return request({
    url: `/products/${productId}`,
    method: 'PUT',
    data
  })
}