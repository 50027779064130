import React from 'react'
import { withRouter, Link } from 'react-router-dom'

class Sidenav extends React.Component {
  constructor(props) {
    super(props)
  }

  _sidebarOptionStyle(option) {
    if (option === this.props.location.pathname) {
      return "list-group-item active"
    } else {
      return "list-group-item"
    }
  }
  render() {
    return (
      <div className="SideNav">
        <div className="navbar text-capitalize bg-primary navbar-dark">
          <div className="navbar-brand ">
            <span className="font-weight-bold">CART</span>&amp;ORDER
          </div>
        </div>
        <div id="sidebar-wrapper">
          <div className="list-group list-group-flush">
            <Link to="/shoppers" className={this._sidebarOptionStyle('/shoppers')}>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
                {' '}
                <path
                  id="icon_dashboard"
                  d="M13,19c-.6,0-1-.475-1-1.187V7.125c0-.713.4-1.187,1-1.187h2c.6,0,1,.475,1,1.187V17.813C16,18.525,15.6,19,15,19ZM7,19c-.6,0-1-.475-1-1.187V1.187C6,.476,6.4,0,7,0H9c.6,0,1,.476,1,1.187V17.813C10,18.525,9.6,19,9,19ZM1,19c-.6,0-1-.475-1-1.187V13.063c0-.713.4-1.188,1-1.188H3c.6,0,1,.476,1,1.188v4.749C4,18.525,3.6,19,3,19Z"
                />{' '}
              </svg> */}
              <svg enableBackground="new 0 0 16.377 16.377" width="16" height="19" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg">
                <g fill="#768c9a">
                  <path d="m4.331 5.043c0.042 0.256 0.141 0.417 0.238 0.52 0.231 1.54 1.521 2.97 2.698 2.97 1.373 0 2.623-1.547 2.865-2.967 0.098-0.101 0.199-0.264 0.242-0.522 0.078-0.289 0.18-0.791 2e-3 -1.025-0.01-0.012-0.02-0.023-0.029-0.034 0.166-0.606 0.377-1.858-0.375-2.711-0.066-0.086-0.486-0.588-1.387-0.854l-0.427-0.149c-0.708-0.219-1.154-0.267-1.172-0.27-0.031-3e-3 -0.065 0-0.096 8e-3 -0.025 7e-3 -0.108 0.029-0.174 0.021-0.169-0.024-0.423 0.063-0.468 0.08-0.06 0.023-1.43 0.573-1.846 1.849-0.039 0.104-0.205 0.646 0.016 1.977-0.033 0.022-0.063 0.049-0.088 0.083-0.178 0.234-0.078 0.735 1e-3 1.024zm0.538-2.902c3e-3 -6e-3 5e-3 -0.013 8e-3 -0.021 0.339-1.052 1.541-1.538 1.549-1.542 0.055-0.021 0.162-0.051 0.219-0.051 0.118 0.016 0.254-5e-3 0.328-0.022 0.121 0.017 0.497 0.078 1.028 0.242l0.432 0.148c0.801 0.237 1.141 0.681 1.143 0.684 6e-3 7e-3 0.012 0.013 0.016 0.019 0.695 0.783 0.338 2.079 0.211 2.457-0.029 0.089-8e-3 0.187 0.057 0.253 0.033 0.034 0.072 0.057 0.115 0.069 2e-3 0.123-0.033 0.348-0.088 0.545-2e-3 9e-3 -4e-3 0.019-6e-3 0.028-0.021 0.139-0.068 0.24-0.131 0.286-0.053 0.04-0.09 0.101-0.1 0.167-0.166 1.19-1.268 2.629-2.382 2.629-0.938 0-2.055-1.325-2.213-2.624-8e-3 -0.068-0.043-0.129-0.099-0.17-0.063-0.048-0.11-0.15-0.131-0.287-1e-3 -0.01-3e-3 -0.02-6e-3 -0.029-0.051-0.183-0.084-0.392-0.087-0.518 0.047-5e-3 0.094-0.021 0.134-0.053 0.074-0.058 0.11-0.152 0.092-0.245-0.275-1.444-0.089-1.965-0.089-1.965z" />
                  <path d="m12.224 9.363c-0.738-0.487-1.855-0.84-1.855-0.84-1.121-0.396-1.129-0.79-1.129-0.79-2.203 4.344-3.876 0.014-3.876 0.014-0.154 0.586-2.423 1.274-2.423 1.274-0.663 0.254-0.943 0.636-0.943 0.636-0.98 1.454-1.096 4.689-1.096 4.689 0.013 0.739 0.332 0.816 0.332 0.816 2.254 1.006 5.792 1.185 5.792 1.185 0.985 0.021 1.894-0.047 2.701-0.154-0.773-0.723-1.262-1.748-1.262-2.887-1e-3 -2.114 1.669-3.841 3.759-3.943z" />
                  <path d="m12.269 9.963c-1.768 0-3.207 1.438-3.207 3.207 0 1.771 1.439 3.207 3.207 3.207 1.77 0 3.207-1.437 3.207-3.207 0-1.768-1.438-3.207-3.207-3.207zm-0.211 4.784c-0.068 0.067-0.178 0.067-0.246 0l-1.543-1.555c-0.068-0.066-0.068-0.178 0-0.245l0.369-0.369c0.068-0.067 0.178-0.067 0.246 0l1.053 1.061 2.045-2.044c0.066-0.068 0.178-0.068 0.246 0l0.367 0.367c0.068 0.068 0.068 0.18 0 0.248l-2.537 2.537z" />
                </g>
              </svg>
              Shoppers
            </Link>
            <Link to="/banners" className={this._sidebarOptionStyle('/banners')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
                {' '}
                <path
                  id="icon_products"
                  d="M14,19V0h2V19ZM0,19V0H2V19Zm11-5.937V0h2V13.063Zm-5,0V0h4V13.063Zm-3,0V0H5V13.063Z"
                />{' '}
              </svg>
              Banners
            </Link>
            <Link to="/inventory" className={this._sidebarOptionStyle("/inventory")}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
                {' '}
                <path
                  id="icon_products"
                  d="M14,19V0h2V19ZM0,19V0H2V19Zm11-5.937V0h2V13.063Zm-5,0V0h4V13.063Zm-3,0V0H5V13.063Z"
                />{' '}
              </svg>
              Inventory
            </Link>
            <Link to="/categories" className={this._sidebarOptionStyle("/categories")}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
                {' '}
                <path
                  id="icon_products"
                  d="M14,19V0h2V19ZM0,19V0H2V19Zm11-5.937V0h2V13.063Zm-5,0V0h4V13.063Zm-3,0V0H5V13.063Z"
                />{' '}
              </svg>
              Categories
            </Link>
            <Link to="/subcategories" className={this._sidebarOptionStyle("/subcategories")}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
                {' '}
                <path
                  id="icon_products"
                  d="M14,19V0h2V19ZM0,19V0H2V19Zm11-5.937V0h2V13.063Zm-5,0V0h4V13.063Zm-3,0V0H5V13.063Z"
                />{' '}
              </svg>
              Subcategories
            </Link>
            {/* <Link to="/orders" className={this._sidebarOptionStyle("/open")}>
              <svg
                id="icon_Invoices"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="19.767"
                viewBox="0 0 14 19.767"
              >
                <path
                  id="Path_102"
                  data-name="Path 102"
                  d="M14,0H2C1.4,0,1,.494,1,1.235V19.767L4,17.3l2,2.471L8,17.3l2,2.471L12,17.3l3,2.471V1.235C15,.494,14.6,0,14,0ZM12,12.355H4V9.884h8Zm0-4.942H4V4.942h8Z"
                  transform="translate(-1)"
                />
              </svg>
              Open Order
            </Link>
            <Link to="/history" className={this._sidebarOptionStyle("/history")}>
              <svg
                id="icon_calendar"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="19.767"
                viewBox="0 0 16 19.767"
              >
                <rect
                  id="Rectangle_557"
                  data-name="Rectangle 557"
                  width="15"
                  height="15"
                  transform="translate(0 0.026)"
                  fill="none"
                />
                <path
                  id="Path_149"
                  data-name="Path 149"
                  d="M2,6.177V17.3H14V6.177ZM13,2.471h2c.6,0,1,.494,1,1.235V18.532c0,.741-.4,1.235-1,1.235H1c-.6,0-1-.494-1-1.235V3.706c0-.741.4-1.235,1-1.235H3V1.235C3,.494,3.4,0,4,0S5,.494,5,1.235V2.471h6V1.235C11,.494,11.4,0,12,0s1,.494,1,1.235ZM12,14.826H10V12.355h2Zm-3,0H7V12.355H9Zm3-3.706H10V8.648h2Zm-3,0H7V8.648H9ZM6,14.826H4V12.355H6Z"
                  fillRule="evenodd"
                />
              </svg>
              Order History
            </Link> */}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(props => <Sidenav {...props} />)