import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import EditProductForm from './components/CreateEditProductForm'
import { updateProduct } from 'services/products'

const EditProduct = ({
  product,
  onEdited,
  categoriesState,
  subcategoriesState,
  onCancel,
}) => {
  const onSubmit = useCallback(async data => {
    // check different
    const payload = {}
    if (data.name && data.name !== product.name) payload.name = data.name
    if (data.price && data.price !== product.price) payload.price = data.price
    if (data.image && data.image !== product.image) payload.image = data.image
    if (data.sku && data.sku !== product.sku) payload.sku = data.sku
    if (data.status !== undefined && data.status !== product.status) payload.status = data.status
    if (data.unit && data.unit !== product.unit) payload.unit = data.unit
    if (data.inventory && data.inventory !== product.inventory) payload.inventory = data.inventory
    if (data.taxUnit && data.taxUnit !== product.taxUnit) payload.taxUnit = data.taxUnit
    if (data.tax && data.tax !== product.tax) payload.tax = data.tax
    if (data.size && data.size !== product.size) payload.size = data.size
    const categoryFound = categoriesState.find(c => c.label === data.category)
    if (categoryFound && product.category !== categoryFound._id) payload.category = categoryFound._id
    const subcategoryFound = subcategoriesState.find(c => c.label === data.subcategory && c.category === categoryFound._id)
    if (subcategoryFound && product.subcategory !== subcategoryFound._id) {
      payload.subcategory = subcategoryFound._id
    }
    let updatedProduct
    if (Object.keys(payload).length > 0) {
      updatedProduct = (await updateProduct(product._id, payload)).data.data
    } else {
      updatedProduct = product
    }
    onEdited(updatedProduct)
  }, [])

  return (
    <div className='edit-product'>
      <EditProductForm
        isCreate={false}
        product={product}
        categories={categoriesState}
        subcategories={subcategoriesState}
        onSubmit={onSubmit}
        onCancel={onCancel}
        key={`edit_product_${product._id}`}
      />
    </div>
  )
}

const mapStateToProps = ({ category }) => ({
  categoriesState: category.categories,
  subcategoriesState: category.subcategories,
})

export default connect(mapStateToProps)(EditProduct)

