import { withStyles } from '@material-ui/core/styles'
import {
  TableCell
} from '@material-ui/core'

export default withStyles(() => ({
  head: {
    backgroundColor: '#edf1f4',
    color: '#7b8a96',
  },
  body: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14,
    fontWeight: 400,
  },
}))(TableCell)
