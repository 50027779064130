import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateCategory } from 'services/categories'
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import {
  TableBody,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';

import Button from 'components/common/button'
import Input from 'components/common/input'
import Dropdown from 'components/common/dropdown'
import {
  Table,
  StyledTableThCell,
  StyledTableCell,
  StyledTableRow,
} from 'components/common/table'

import { openToast as openToastState } from 'reducers/toast/actions'
import { loadCates } from 'utils/state_init'

import './_styles.scss'

const useStyles = makeStyles({
  actionColumn: {
    width: '200px',
  }
});

const taxUnitObj = {
  percent: 'Percent',
  fixed: 'Dollar'
}
const taxUnits = Object.entries(taxUnitObj).map(([value, name]) => ({ value, name }))

const CategoryRow = ({
  category,
  index,
  onUpdate,
  onClick,
}) => {
  const [editing, setEditing] = useState(false)
  const editCategory = (category) => {
    onUpdate(category)
    setEditing(false)
  }
  return !editing ? (
    <CategoryRowNotEditing
      category={category}
      index={index}
      setEditing={setEditing}
      onClick={onClick}
    />
  ) : (
    <CategoryRowEditing
      category={category}
      index={index}
      setEditing={setEditing}
      onUpdate={editCategory}
    />
  )
}
const CategoryRowNotEditing = ({
  category,
  index,
  setEditing,
  ...props
}) => {
  const onClickEdit = e => {
    e.stopPropagation()
    setEditing(true)
  }
  return (
    <StyledTableRow key={category._id} {...props}>
      <StyledTableCell component='th' scope='row'>
        {index + 1}
      </StyledTableCell>
      <StyledTableCell align='left'>{category.label}</StyledTableCell>
      <StyledTableCell align='left'>{taxUnitObj[category.taxUnit]}</StyledTableCell>
      <StyledTableCell align='left'>{category.tax}</StyledTableCell>
      <StyledTableCell align='left'>
        <div className='actions'>
          <Button onClick={onClickEdit} className='outlined'>
            Edit
          </Button>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  )
}
const CategoryRowEditing = ({
  category,
  index,
  setEditing,
  onUpdate,
}) => {
  const { control } = useForm({
    defaultValues: {
      taxUnit: category.taxUnit,
    }
  });
  const [tempCategory, setTempCategory] = useState({ ...category })
  const updateField = (field, value) => {
    if (field === 'taxUnit') {
      tempCategory.taxUnitName = taxUnitObj[value]
    }
    tempCategory[field] = value
    setTempCategory({ ...tempCategory })
  }
  return (
    <StyledTableRow key={category._id}>
      <StyledTableCell component='th' scope='row'>
        {index + 1}
      </StyledTableCell>
      <StyledTableCell align='left'>
        <Input
          type='text'
          value={tempCategory.label}
          onChange={e => updateField('label', e.target.value)}
        />
      </StyledTableCell>
      <StyledTableCell align='left'>
        <Dropdown
          handleChange={(event) => updateField('taxUnit', event.target.value)}
          items={taxUnits}
          control={control}
          name='taxUnit'
          value={tempCategory.taxUnit}
        />
      </StyledTableCell>
      <StyledTableCell align='left'>
        <Input
          type='text'
          value={tempCategory.tax}
          onChange={e => updateField('tax', e.target.value)}
        />
      </StyledTableCell>
      <StyledTableCell align='left'>
        <div className='actions'>
          <Button onClick={() => onUpdate(tempCategory)}>Update</Button>
          <Button className='outlined' onClick={() => setEditing(false)}>Cancel</Button>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  )
}

function Categories({
  categories,
  openToast,
  dispatch,
}) {
  const history = useHistory()
  const classes = useStyles()
  const updateCategoryDetail = async category => {
    const data = {
      id: category._id,
      label: category.label,
      taxUnit: category.taxUnit,
      tax: category.tax,
    }
    try {
      await updateCategory(data)
    } catch (err) {
      return console.log(err)
    }
    openToast('Update Successfully', 'success')
    loadCates(dispatch)
  }
  const onSelectCate = (cate) => {
    history.push(`/subcategories?category=${cate._id}`)
  }
  return (
    <Box className='open-order-page'>
      <Box component='div' mb={2} px={3}>
        <Box
          component='div'
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          height='100%'
          py={2.5}
        >
          <Box
            component='div'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            className='font-26 text-dark-blue'
          >
            Categories
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableThCell />
              <StyledTableThCell align='left'>LABEL</StyledTableThCell>
              <StyledTableThCell align='left'>TAX UNIT</StyledTableThCell>
              <StyledTableThCell align='left'>TAX</StyledTableThCell>
              <StyledTableThCell align='left' className={classes.actionColumn} />
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((cate, i) => (
              <CategoryRow
                category={cate}
                index={i}
                onUpdate={updateCategoryDetail}
                onClick={() => onSelectCate(cate)}
                key={cate._id}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ category }) => ({
  categories: category.categories,
})
const mapDispatchToProps = (dispatch) => ({
  openToast: (message, type) => dispatch(openToastState({ message, type })),
  dispatch,
})
export default connect(mapStateToProps, mapDispatchToProps)(Categories)
