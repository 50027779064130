import axios from 'axios'
import { openToast } from 'reducers/toast/actions'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

export const setAxiosDispatch = dispatch => {
  axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      config.headers.authorization = `${token}`;
      return config;
    },
    error => {
      dispatch(openToast(error.message))
      return Promise.reject(error);
    }
  )
  axios.interceptors.response.use(
    response => {
      if (response.data?.result !== 'success') dispatch(openToast({ message: response.data?.err, type: 'error' }))
      return response
    },
    error => {
      dispatch(openToast({ message: error.response.data?.err || error.message, type: 'error' }))
      return Promise.reject(error);
    }
  )
}

export default axios
