import React, { useState } from 'react'
import { connect } from 'react-redux'
import {  useHistory } from 'react-router-dom'
import { Collapse } from 'reactstrap'
import { DropdownItem } from 'reactstrap'
import { DropdownMenu } from 'reactstrap'
import { DropdownToggle } from 'reactstrap'
import { Nav } from 'reactstrap'
import { Navbar } from 'reactstrap'
import { NavbarBrand } from 'reactstrap'
import { NavbarToggler } from 'reactstrap'
import { NavItem } from 'reactstrap'
import { UncontrolledDropdown } from 'reactstrap'

import InitialsBadge from 'components/Navigation/Header/InitialsBadge'

import { logout } from 'reducers/user/actions'

function Header ({
  user,
  logoutState,
}) {
  const [isOpen, setIsOpen] = useState(false)

  const history = useHistory()

  const toggle = () => setIsOpen(!isOpen)

  const onLogout = () => {
    logoutState()
    history.push('/login')
  }

  return (
    <div className="Header">
      <Navbar color="white" light expand="md">
        <span className="mx-2">
          <InitialsBadge text="SN" rounded="rounded-circle" backgroundColor="yellow" />
        </span>
        <NavbarBrand>{user.storeName}</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem className="notification-item">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path
                    id="Notification_Icon"
                    data-name="Notification Icon"
                    d="M-1371,81h4a2.006,2.006,0,0,1-2,2A2.006,2.006,0,0,1-1371,81Zm-5-1a.945.945,0,0,1-1-1,.945.945,0,0,1,1-1h.5a4.354,4.354,0,0,0,1.5-3V72a4.952,4.952,0,0,1,5-5,4.951,4.951,0,0,1,5,5v3a4.351,4.351,0,0,0,1.5,3h.5a.945.945,0,0,1,1,1,.945.945,0,0,1-1,1Z"
                    transform="translate(1377 -67)"
                    fill="#bcbcbc"
                  />
                </svg>
              </span>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                <span className="profile-name">
                  {user.firstName}
                  <svg
                    id="small-down"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                  >
                    <path
                      id="Path_26"
                      data-name="Path 26"
                      d="M8.1,11.6,2.6,6.041,4.026,4.6,8.1,8.718,12.174,4.6,13.6,6.041Z"
                      transform="translate(-2.6 -4.6)"
                      fill="#bcbcbc"
                    />
                  </svg>
                </span>
                <InitialsBadge text={`${(user.firstName || '')[0]}${(user.lastName || '')[0]}`} rounded="rounded-circle" backgroundColor="indigo" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="/profile">
                  Profile
                </DropdownItem>
                <DropdownItem>Settings</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={onLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  user,
})
const mapDispatchToProps = dispatch => ({
  logoutState: () => dispatch(logout())
})
export default connect(mapStateToProps, mapDispatchToProps)(Header)
