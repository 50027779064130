import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { useHistory } from 'react-router'

import Input from 'components/common/input';
import Button from 'components/common/button';
import Checkbox from 'components/common/checkbox'
import backgroundImg from '../../../assets/images/bg_registration.jpg';

// network
import { login } from 'services/user'

// redux
import { setAfterLogin } from '../../../reducers/user/actions'

import './_Login.scss'

const Login = ({ setUser, dispatch }) => {
  const history = useHistory()

  const [remember, setRemember] = useState(false)
  const [error, setError] = useState('')

  const { handleSubmit, register, watch, errors } = useForm()

  const onSubmit = async (payload) => {
    let data
    try {
      const res = await login(
        payload.username,
        payload.password,
        payload.remember,
      )
      data = res.data
    } catch (err) {
      return setError(err.response.data.err)
    }
    // res should contain email
    if (data.result !== 'success') {
      return setError(data.err)
    }
    await setUser(data.data)
    history.push('/')
  };

  let errorUsername = ''
  let errorPasswordMessage = ''
  if (errors) {
    if (errors.errorUsername) {
      errorUsername = errors.errorUsername.message;
    }
    if (errors.password) {
      errorPasswordMessage = errors.password.message;
    }
  }

  return (
    <div className="Registration">
      <div className="Registration-container">
        <div className="Registration-blockLeft">
          <img className="Registration-blockLeftImg" src={backgroundImg} alt="registration img" />
        </div>
        <div className="Registration-blockRight">
          <form onSubmit={handleSubmit(onSubmit)} className="Registration-form">
            <div className="Registration-title">
              <span className="Registration-titleCart">CART</span>&ORDER
                        </div>
            <div className="Registration-subTitle">
              <span className="Registration-subTitleCart">Admin</span>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  label="Username"
                  type="text"
                  error={errors.username}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('username')}
                  name="username"
                />
              </div>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  errorMessage={errorPasswordMessage}
                  label="Password"
                  type="password"
                  error={errors.password}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('password')}
                  name="password"
                />
              </div>
            </div>
            {error && (
              <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
            )}
            <div className="Registration-blockCheckbox">
              <div>
                <Checkbox
                  label="Remember me"
                  checked={remember}
                  onClick={() => setRemember(!remember)}
                />
              </div>
            </div>
            <div className="Registration-blockButton">
              <Button
                type="submit"
                onClick={() => {
                }}
                disabled={false}
                classes='success'
              >
                Login
                            </Button>
            </div>
            <div className="Registration-blocLink">
              <a className="Registration-link" href="/signup">
                Don’t have an account? Sign up.
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setAfterLogin(user)),
  dispatch,
})
export default connect(null, mapDispatchToProps)(Login);
