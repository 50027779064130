import React from 'react'

import './_styles.scss'

const AvailableProducts = ({ products, onSelect }) => {
  return (
    <div className='available-products'>
      {products.map(product => (
        <div
          className='available-product'
          key={product._id}
          onClick={() => onSelect(product)}>
          <img src={product.image} />
          {product.name}
        </div>
      ))}
    </div>
  )
}

export default AvailableProducts
