import React from 'react'

function Spinner({ show, noBackdrop }) {
  return show ? (
    <>
      {!noBackdrop && <div className="Spinner-backdrop show" style={{ zIndex: 1055 }} />}
      <div className="Spinner spinner-border text-primary" role="status" style={{ zIndex: 1054 }}>
        <span className="sr-only">Loading...</span>
      </div>
    </>
  ) : null
}

export default Spinner
