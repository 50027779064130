import React, { useState, useEffect } from "react"

import {
  Box,
  FormControlLabel,
} from "@material-ui/core"
import { useForm } from 'react-hook-form'

import SearchInput from 'components/common/input/searchInput'
import Dropdown from 'components/common/dropdown'
import Button from 'components/common/button'
import Modal from 'components/common/modal'

import CategoryList from './components/category-list'
import SubcategoryList from './components/subcategory-list'
import CreateCategory from '../CreateCategory'
import CreateSubcategory from '../CreateSubcategory'

import './_styles.scss'

const inStocks = [
  {
    name: 'All',
    value: -1,
  },
  {
    name: 'In Stock',
    value: 1
  },
  {
    name: 'Out of Stock',
    value: 0,
  }
]
const defaultFormValue = {
  inStock: inStocks[0].value
}
const InventoryFilter = ({ onFilter }) => {
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false)
  const [openAddSubcategoryModal, setOpenAddSubcategoryModal] = useState(false)

  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedSubcategory, setSelectedSubcategory] = useState()

  const { register, watch, control } = useForm({ defaultValues: defaultFormValue })

  useEffect(() => {
    if (selectedCategory || selectedSubcategory) {
      onSearch()
    }
  }, [selectedCategory, selectedSubcategory])
  const onSearch = () => {
    const filter = {
      name: watch('keyword') || undefined,
      status: watch('inStock') === -1 ? undefined : watch('inStock'),
      category: selectedCategory,
      subcategory: selectedSubcategory,
    }
    onFilter && onFilter(filter, 0)
  }
  const onSelectCategory = async (category) => {
    if (category && category._id) {
      await setSelectedCategory(category._id)
    } else {
      await setSelectedCategory()
    }
    await setSelectedSubcategory()
  }
  const onSelectSubcategory = async (subcategory) => {
    if (subcategory && subcategory._id) {
      await setSelectedSubcategory(subcategory._id)
    } else {
      await setSelectedSubcategory()
    }
  }
  return (
    <Box component="div" mb={2} className="inventory-filter">
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        height="100%"
        py={2.5}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className="font-26 text-dark-blue"
        >
          Inventory
        </Box>
      </Box>
      <div className='i-f-container'>
        <div className='i-f-form'>
          <div className='i-f-form-row'>
            <SearchInput
              name='keyword'
              refInput={register({
                required: true,
              })}
              value={watch('keyword')}
            />
          </div>
          <div className='i-f-form-row mb-3'>
            <CategoryList
              onAddCategory={() => setOpenAddCategoryModal(true)}
              onSelectItem={onSelectCategory}
            />
          </div>
          {selectedCategory && (
            <div className='i-f-form-row mb-3'>
              <SubcategoryList
                onAddSubcategory={() => setOpenAddSubcategoryModal(true)}
                onSelectItem={onSelectSubcategory}
                category={selectedCategory}
              />
            </div>
          )}
          <div className='i-f-form-row'>
            {/* <Dropdown
              label='Category'
              name='category'
              value={watch('category')}
              control={control}
              items={categories}
            /> */}
            <Dropdown
              label='Stock'
              name='inStock'
              value={watch('inStock')}
              control={control}
              items={inStocks}
              styles={{
                width: '25%'
              }}
            />
            {/* <div>
              <FormControlLabel
                control={<GreenCheckbox
                  name='myproducts'
                  inputRef={register}
                />}
                label='My Products'
                className="i-f-checkbox"
              />
            </div> */}
          </div>
          <div className='i-f-form-row-submit'>
            <Button className='clr-btn'>
              Clear Search
            </Button>
            <Button
              className='submit-btn'
              onClick={onSearch}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={openAddCategoryModal}
        onClose={() => setOpenAddCategoryModal(false)}
      >
        <CreateCategory onDone={() => setOpenAddCategoryModal(false)} />
      </Modal>
      <Modal
        open={openAddSubcategoryModal}
        onClose={() => setOpenAddSubcategoryModal(false)}
      >
        <CreateSubcategory
          onDone={() => setOpenAddSubcategoryModal(false)}
          selectedCategory={selectedCategory}
        />
      </Modal>
    </Box>
  )
}

export default InventoryFilter;
