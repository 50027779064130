import {
  SET_BUILDINGS
} from './constants'

export const setBuildings = (buildings) => {
  return {
    type: SET_BUILDINGS,
    payload: buildings,
  }
}
