import {
  SET_BUILDINGS
} from './constants'

export const initState = {
  buildings: [],
  buildingsObj: undefined,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_BUILDINGS:
      return {
        ...state,
        buildings: payload,
        buildingsObj: payload.reduce((acc, cur) => {
          acc[cur._id] = cur
          return acc
        }, {})
      }

    default:
      return state
  }
}