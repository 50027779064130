import React, { useState } from "react";
import { connect } from 'react-redux'
import {
  Box,
} from "@material-ui/core"
import { useForm } from 'react-hook-form'

import Button from 'components/common/button'
import Input from 'components/common/input'

import './_styles.scss'

import { createCategory } from 'services/categories'

const CreateCategory = ({
  store,
  onDone,
}) => {
  const { handleSubmit, register, watch, control, errors } = useForm()
  const [onSubmitting, setOnSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')

  const submit = async () => {
    const category = watch('newCategory')
    if (!category) return setSubmitError('Please enter category')
    let res
    try {
      res = (await createCategory(store._id, category)).data
    } catch (err) {
      setOnSubmitting(false)
      return setSubmitError(err.response.data.err)
    }
    if (res.result !== 'success') {
      setOnSubmitting(false)
      return setSubmitError(res.err)
    }
    // refresh category list
    onDone()
  }

  return (
    <Box component="div" mb={2} className="add-category">
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        height="100%"
        py={2.5}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className="font-26 text-dark-blue"
        >
          Create Category
        </Box>
      </Box>
      <div className='i-f-container'>
        <form onSubmit={handleSubmit(submit)} className='i-f-form'>
          <div className='i-f-form-row'>
            <Input
              label="New Category"
              type="text"
              value={watch('newCategory')}
              refInput={register({
                required: true
              })}
              error={errors.newCategory}
              name="newCategory"
            />
          </div>
          {submitError && <div className="Input-error">{submitError}</div>}
          <div className='i-f-form-row-submit'>
            <Button
              type='submit'
              className='submit-btn'
              disabled={onSubmitting}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Box>
  )
}

const mapStateToProps = ({ category, store }) => ({
  categories: category.categories,
  store: store.store,
})

export default connect(mapStateToProps)(CreateCategory)
