import { SET_USER, CLEAR_USER } from './constants'


export const setUser = ({
  _id,
  firstName,
  lastName,
  username,
  email,
  phoneNumber,
  address,
  storeName,
  storeType,
  status,
  token,
  createdAt,
  stripeAccount,
}) => {
  return {
    type: SET_USER,
    payload: {
      _id,
      firstName,
      lastName,
      username,
      email,
      phoneNumber,
      address,
      storeName,
      storeType,
      status,
      token,
      createdAt,
      stripeAccount,
    }
  }
}

export const setAfterLogin = ({
  _id,
  firstName,
  lastName,
  username,
  email,
  phoneNumber,
  address,
  storeName,
  storeType,
  status,
  token,
}) => {
  return {
    type: SET_USER,
    payload: {
      _id,
      firstName,
      lastName,
      username,
      email,
      phoneNumber,
      address,
      storeName,
      storeType,
      status,
      token,
    }
  }
}

export const logout = () => {
  return {
    type: CLEAR_USER,
  }
}
