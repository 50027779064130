import React from 'react'
import { Avatar } from '@material-ui/core'

function InitialsBadge({ text, avatar, backgroundColor, rounded, ...props }) {
  const classes = ['InitialsBadge-after', 'InitialsBadge-text', `bg-${backgroundColor || 'black'}`]
  if (rounded) classes.push(rounded)

  return (
    <span className="InitialsBadge-wrapper">
      <span className="InitialsBadge">
        {avatar ? (
          <div className='InitialsBadge-avatar'>
            <Avatar src={avatar} />
          </div>
        ) : (
          <div className={classes.join(' ')}>{text}</div>
        )}
      </span>
    </span>
  )
}

export default InitialsBadge
