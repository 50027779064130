import React, { useState, useCallback, memo } from 'react'
import {
  FormControlLabel,
} from "@material-ui/core"
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import OutsideClickHandler from 'react-outside-click-handler'

import Dropdown from 'components/common/dropdown'
import Button from 'components/common/button'
import Input from 'components/common/input'

import ImageCropper from 'components/common/image-cropper'
import AvailableProducts from '../../AvailableProducts'
import { GreenCheckbox } from '../../withstyles'

// import './_styles.scss'

import { upload } from 'services/file'
import { getProducts } from 'services/products'

const taxUnits = [
  {
    name: 'Percent',
    value: 'percent',
  },
  {
    name: 'Dollar',
    value: 'fixed',
  },
]

const statuses = [
  {
    name: 'Show',
    value: 'true',
  },
  {
    name: 'Hide',
    value: 'false',
  },
]

const convertStatus = status => status ? 'true' : 'false'
const revertStatus = strStatus => strStatus === 'true' ? 1 : 0

const getdefaultFormValue = ({ product, categories, subcategories }) => ({
  unit: product.unit,
  status: convertStatus(product.status),
  category: product._id ? (categories.find(c => c._id === product.category) || {})._id : categories[0]._id,
  subcategory: product._id ? (subcategories.find(c => c._id === product.subcategory) || {})._id :
    subcategories.find(s => s.category === categories[0]._id)?._id,
  productName: product.name,
  price: product.price,
  inventory: product.inventory,
  sku: product.sku,
  taxUnit: product.taxUnit,
  tax: product.tax,
  size: product.size,
})
const CreateEditProductForm = memo(({
  product = {},
  categories,
  subcategories: subcategoriesState,
  isCreate,
  onSubmit,
  onCancel,
}) => {
  const { handleSubmit, register, watch, control, errors, setValue } = useForm({
    defaultValues: getdefaultFormValue({
      product,
      categories,
      subcategories: subcategoriesState,
    })
  })
  const [availableProducts, setAvailableProducts] = useState([])
  const [croppedImage, setCroppedImage] = useState({
    blob: product.image || '',
    base64: '',
  })
  const [onSubmitting, setOnSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')

  // internal categories, we can change as we want, keep store unchanged
  const [subcategories, setSubcategories] = useState(subcategoriesState.filter(s => s.category === product._id ? product.category : categories[0]._id))


  /**
   * @param {object} img blob, base64
   */
  const onCroppedImg = img => {
    setCroppedImage(img)
  }

  const searchCb = useCallback(_.debounce(async name => {
    let products
    try {
      products = (await getProducts({
        filter: { name }
      })).data.data
    } catch (err) {
      return console.error(err)
    }
    setAvailableProducts(products)
  }, 1000), [])
  const onProductNameChange = event => {
    // not show product suggest for editing
    isCreate && searchCb(event.target.value)
  }

  const onSelectAvailableProduct = async product => {
    setValue('productName', product.name)
    setValue('price', product.price)
    setValue('unit', 'item')

    // maybe product category not in list yet, we must manually add them first
    // in order for set category value to take effect
    // if (categories.findIndex(c => c._id === product.category._id) === -1) {
    //   await setCategories([
    //     ...categories,
    //     { name: product.category.label, value: product.category._id }
    //   ])
    // }
    // if (subcategories.findIndex(c => c._id === product.subcategory._id) === -1) {
    //   await setSubcategories([
    //     ...subcategories,
    //     { name: product.subcategory.label, value: product.subcategory._id }
    //   ])
    // }
    setValue('category', product.category._id)
    setValue('subcategory', product.subcategory._id)
    setValue('taxUnit', product.taxUnit)
    setValue('tax', product.tax)
    setValue('status', product.status)
    // set image
    setCroppedImage({
      blob: product.image,
      base64: ''
    })
    // empty the list
    setAvailableProducts([])
  }

  const submit = async () => {
    const category = watch('category')
    if (!category) return setSubmitError('Please select category')
    const subcategory = watch('subcategory')
    const productName = watch('productName')
    if (!category) return setSubmitError('Please enter product name')
    const price = Number(watch('price'))
    if (!price) return setSubmitError('Please enter product price')
    const sku = watch('sku')
    const unit = watch('unit')
    const inventory = Number(watch('inventory'))
    const status = revertStatus(watch('status'))
    const taxUnit = watch('taxUnit')
    const tax = watch('tax')
    const size = watch('size')

    let image
    // user upload image
    if (croppedImage.base64) {
      // upload
      let uploadImgRes
      try {
        const res = await upload(croppedImage.base64)
        uploadImgRes = res.data
      } catch (err) {
        return setSubmitError(err.response.data.err)
      }
      if (uploadImgRes.result !== 'success') {
        return setSubmitError(uploadImgRes.err)
      }
      image = uploadImgRes.file._id
    } else {
      // user select product
      image = croppedImage.blob
    }
    const data = {
      name: productName,
      category,
      subcategory,
      price,
      image,
      sku,
      unit,
      status,
      inventory,
      taxUnit,
      tax,
      size,
    }
    onSubmit(data)
  }

  const onCategoryChange = event => {
    const subcates = subcategoriesState.filter(s => s.category === event.target.value)
    setSubcategories(subcates)
  }

  return (
    <div className='i-f-container'>
      <div className='i-f-form-img'>
        <ImageCropper
          onCroppedImg={onCroppedImg}
          btnClass='outlined'
          setImg={croppedImage.blob || product.image}
        />
      </div>
      <form
        onSubmit={handleSubmit(submit)}
        className='i-f-form'
        key={isCreate ? 'create_product' : `edit_product_${product._id}`}
      >
        <div className='i-f-form-row'>
          <div style={{
            position: 'relative'
          }}>
            <Input
              label='Product Name'
              type='text'
              value={watch('productName')}
              refInput={register({
                required: true,
              })}
              name='productName'
              error={errors.productName}
              onChange={onProductNameChange}
            />
            {availableProducts.length > 0 && (
              <OutsideClickHandler
                onOutsideClick={() => setAvailableProducts([])}
              >
                <AvailableProducts
                  products={availableProducts}
                  onSelect={onSelectAvailableProduct}
                />
              </OutsideClickHandler>
            )}
          </div>
        </div>
        <div className='i-f-form-row'>
          <Dropdown
            label='Category'
            name='category'
            value={watch('category')}
            control={control}
            items={categories}
            refInput={register({
              required: true,
            })}
            required={true}
            error={errors.category}
            handleChange={onCategoryChange}
          />
          <Dropdown
            label='Subcategory (Optional)'
            name='subcategory'
            value={watch('subcategory')}
            control={control}
            items={subcategories}
            refInput={register()}
            error={errors.subcategory}
          />
        </div>
        <div className='i-f-form-row'>
          <Input
            label='Unit'
            type='text'
            value={watch('unit')}
            refInput={register({
              required: true,
            })}
            name='unit'
            error={errors.unit}
          />
          <Input
            label='Price'
            type='number'
            value={watch('price')}
            refInput={register({
              required: true,
            })}
            name='price'
            error={errors.price}
            step='0.01'
          />
          <Input
            label='Size'
            type='text'
            value={watch('size')}
            refInput={register({
              required: true,
            })}
            name='size'
            error={errors.size}
          />
        </div>
        <div className='i-f-form-row'>
          {/* <div>
            <FormControlLabel
              control={<GreenCheckbox
                inputRef={register}
                checked={inStock}
                onClick={() => setInStock(!inStock)}
              />}
              label='In Stock'
              className="i-f-checkbox"
            />
          </div> */}
          <Input
            label='Inventory (Optional)'
            type='number'
            value={watch('inventory')}
            refInput={register()}
            name='inventory'
            error={errors.inventory}
          />
          <Dropdown
            label='Visible'
            name='status'
            value={watch('status')}
            control={control}
            items={statuses}
            refInput={register()}
            error={errors.status}
          />
        </div>
        <div className='i-f-form-row'>
          <Dropdown
            label='Tax Unit (Optional)'
            name='taxUnit'
            value={watch('taxUnit')}
            control={control}
            items={taxUnits}
            refInput={register({
              required: true,
            })}
            error={errors.taxUnit}
          />
          <Input
            label='Tax (Optional)'
            type='text'
            value={watch('tax')}
            refInput={register()}
            name='tax'
            error={errors.tax}
          />
        </div>
        {submitError && <div className='Input-error'>{submitError}</div>}
        <div className='i-f-form-row-submit'>
          <Button
            className='outlined'
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            className='submit-btn'
            disabled={onSubmitting}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}, (prev, next) => false)

export default CreateEditProductForm
