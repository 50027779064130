import React, { Component, useState, useEffect } from "react";
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Box,
  Paper,
  // Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import cx from "classnames";

import {
  CatalogTableCell,
  CatalogTableRow,
  AddProductButton,
} from "./withstyles";

import Modal from 'components/common/modal'
import CreateProduct from './CreateProduct'
import EditProduct from './CreateProduct/EditProduct'

import "./_Inventory.scss";

import { getProducts } from 'services/products'
import InventoryFilter from "./Filter/Filter";

const PAGE_LIMIT = 20

const Inventory = ({ categories }) => {
  const [products, setProducts] = useState([])
  const [page, setPage] = useState(0)
  const [filterState, setFilterState] = useState({})
  const [categoryData, setCategoryData] = useState({})

  const [openAddProductModal, setOpenAddProductModal] = useState(false)

  const [hasMore, setHasMore] = useState(true)
  const [editingIndexes, setEditingIndexes] = useState({})

  useEffect(() => {
    fetchData()
  }, [])
  useEffect(() => {
    onCategoriesChanged(categories)
  }, [categories])

  const onCategoriesChanged = async (categories) => {
    const categoryData = {}
    // build categoryData object
    categories.forEach(cat => {
      if (!categoryData[cat._id]) {
        categoryData[cat._id] = {
          ...cat,
          products: []
        }
      }
    })
    setCategoryData({ ...categoryData })
  }

  const fetchData = async (filter = filterState, p = page, sort = { name: 1 }) => {
    const { products: productsRes } = (await getProducts(filter, p, sort)).data.data
    if (p === 0) {
      setProducts(productsRes)
    } else {
      setProducts([...products.concat(productsRes)])
    }
    if (productsRes.length > 0) {
      if (productsRes.length < PAGE_LIMIT) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
    } else {
      setHasMore(false)
    }
    setFilterState(filter)
    setPage(p + 1)
    setEditingIndexes({})
  }

  const onProductCreated = async (refresh = false) => {
    if (refresh) fetchData(undefined, 0)
    setOpenAddProductModal(false)
  };

  const toggleEditing = (index) => {
    const curr = editingIndexes[index]
    setEditingIndexes({
      ...editingIndexes,
      [index]: !curr,
    })
  }
  const onProductEdited = async (index, product) => {
    products[index] = product
    setProducts([...products])
    toggleEditing(index)
  }
  return (
    <Box>
      <Box component='div' mb={2} px={3}>
        <InventoryFilter onFilter={fetchData} />
      </Box>
      <Box component='div' mb={2} px={3}>
        <Box
          component='div'
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          height='100%'
          py={2.5}
        >
          <Box
            component='div'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            className='font-26 text-dark-blue'
          >
            Catalog List
          </Box>
          <Box component='div' pr={3}>
            <AddProductButton variant='outlined' onClick={() => setOpenAddProductModal(true)}>
              Add Product
              </AddProductButton>
          </Box>
        </Box>
        <TableContainer component={Paper} elevation={0}>
          <Box component='div' py={2} px={3}>
            <InfiniteScroll
              dataLength={products.length}
              hasMore={hasMore}
              next={fetchData}
              loader={<h4>Loading...</h4>}
              height={368}
            >
              <Table aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <CatalogTableCell>&nbsp;</CatalogTableCell>
                    <CatalogTableCell>PRODUCT</CatalogTableCell>
                    <CatalogTableCell>CATEGORY</CatalogTableCell>
                    <CatalogTableCell>PRICE</CatalogTableCell>
                    <CatalogTableCell>SIZE</CatalogTableCell>
                    {/* <CatalogTableCell>INVENTORY</CatalogTableCell> */}
                    <CatalogTableCell>VISIBLE</CatalogTableCell>
                    {/* <CatalogTableCell>IN STOCK</CatalogTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((data, i) => {
                    const {
                      _id,
                      name,
                      category,
                      price,
                      unit,
                      size,
                      status,
                    } = data || {};
                    return (
                      <React.Fragment>
                        <CatalogTableRow
                          key={_id} // TODO: use id for this
                          className={cx(true && "active_list")}
                          onClick={() => toggleEditing(i)}
                        >
                          <CatalogTableCell>{i + 1}</CatalogTableCell>
                          <CatalogTableCell>{name}</CatalogTableCell>
                          <CatalogTableCell>{categoryData[category] && categoryData[category].label}</CatalogTableCell>
                          <CatalogTableCell>
                            <Typography component='div'>
                              <Grid
                                component='label'
                                container
                                alignItems='center'
                                spacing={1}
                              >
                                {(false && (
                                  <Box component='div'>
                                    <TextField
                                      id='outlined-basic'
                                      placeholder='$'
                                      color='secondary'
                                      variant='outlined'
                                      size='small'
                                      style={{ width: 147 }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <Box
                                              component='div'
                                              className='font-9'
                                              borderRadius={10}
                                              border={1}
                                              borderColor={"#7b8a96"}
                                              height={20}
                                              width={64}
                                              display='flex'
                                              flexDirection='row'
                                              overflow='hidden'
                                              mr={0.4}
                                              fontFamily="'Poppins', sans-serif"
                                              fontSize='9px'
                                            >
                                              <Box
                                                component='div'
                                                display='flex'
                                                justifyContent='center'
                                                flexDirection='column'
                                                alignItems='center'
                                                width='100%'
                                                height='100%'
                                              >
                                                Item
                                          </Box>
                                              <Box
                                                component='div'
                                                display='flex'
                                                justifyContent='center'
                                                flexDirection='column'
                                                alignItems='center'
                                                width='100%'
                                                height='100%'
                                                className='bg-blue-variant-1 text-white'
                                                bgcolor='#7B8A96'
                                              >
                                                lb
                                          </Box>
                                            </Box>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Box>
                                )) || (
                                    <>
                                      <Grid item>
                                        <Box component='div' className='font-14'>
                                          ${price}/{unit}
                                        </Box>
                                      </Grid>
                                    </>
                                  )}
                              </Grid>
                            </Typography>
                          </CatalogTableCell>
                          {/* <CatalogTableCell>
                          {(false && (
                            <Box component="div">
                              <TextField
                                id="outlined-basic"
                                placeholder="Kg"
                                color="secondary"
                                variant="outlined"
                                size="small"
                                style={{ width: 73 }}
                              />
                            </Box>
                          )) || <>{unitValue} {unit}</>}
                        </CatalogTableCell> */}
                          <CatalogTableCell>{size}</CatalogTableCell>
                          {/* <CatalogTableCell>{inventory}</CatalogTableCell> */}
                          {/* <CatalogTableCell>{status ? 'Show' : 'Hide'}</CatalogTableCell> */}
                          <CatalogTableCell>
                            <Box
                              component='div'
                              borderRadius='50%'
                              width={15}
                              height={15}
                              className={cx(status ? "bg-green" : "bg-red")}
                            />
                          </CatalogTableCell>
                        </CatalogTableRow>
                        {editingIndexes[i] && (
                          <CatalogTableRow>
                            <CatalogTableCell colSpan={10}>
                              <EditProduct
                                key={`edit_product_${data._id}`}
                                product={data}
                                onEdited={product => onProductEdited(i, product)}
                                onCancel={() => toggleEditing(i)}
                              />
                            </CatalogTableCell>
                          </CatalogTableRow>
                        )}
                      </React.Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </Box>
        </TableContainer>
        {/* <Box
          component="div"
          py={2.5}
          display="flex"
          justifyContent="flex-end"
          pr={3}
        >
          <SubmitButton variant="contained">
            Save
          </SubmitButton>
        </Box> */}
      </Box>
      <Modal
        open={openAddProductModal}
        onClose={() => setOpenAddProductModal(false)}
        styles={{
          width: '70%',
          height: '100%',
        }}
      >
        <CreateProduct
          onDone={onProductCreated}
        />
      </Modal>
    </Box>
  );
}

const mapStateToProps = ({ category }) => ({
  categories: category.categories,
})

export default connect(mapStateToProps)(Inventory);
