import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { Box, Paper, Button } from '@material-ui/core'
import Modal from 'components/common/modal'
import Checkbox from 'components/common/checkbox'
import CreateBannerForm from './CreateBannerForm'

import './_styles.scss'

import { openToast as openToastState } from 'reducers/toast/actions'

import { getBanners, updateBannerStatus, removeBanner } from 'services/banners'

const Banners = ({ openToast }) => {
  // const banners = [
  //   { image: 'https://customer.huynhph.co/static/media/food.95519246.png', createdAt: new Date() },
  //   { image: 'https://customer.huynhph.co/static/media/food.95519246.png', createdAt: new Date() },
  // ]
  const [banners, setBanners] = useState([])
  const [openAddBannerModal, setOpenAddBannerModal] = useState(false)
  useEffect(() => {
    loadBanners()
  }, [])
  const loadBanners = async () => {
    let banners
    try {
      banners = (await getBanners()).data.data
    } catch (err) {
      openToast(err.response?.data?.err)
      return console.error(err)
    }
    setBanners(banners)
  }
  const onBannerAdded = () => {
    // refresh list
    setOpenAddBannerModal(false)
  }
  const toggleStatus = async i => {
    const banner = banners[i]
    let updatedBanner
    try {
      updatedBanner = (await updateBannerStatus(banner._id, !banner.status)).data.data
    } catch (err) {
      openToast(err.response?.data?.err)
      return console.error(err)
    }
    banners[i] = updatedBanner
    setBanners([...banners])
  }
  const removeBanner = async (i) => {
    const banner = banners[i]
    console.log(banner)
  }
  return (
    <Box className="banners">
      <Box component="div" mb={2} px={3}>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="100%"
          py={2.5}
        >
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className="font-26 text-dark-blue"
          >
            Banners
          </Box>
          <Box component="div" pr={3}>
            <Button
              className="add-banner-btn"
              variant="outlined"
              onClick={() => setOpenAddBannerModal(true)}
            >
              Add Banner
            </Button>
          </Box>
        </Box>
        <Box component={Paper} py={2} px={3}>
          {banners.map((banner, i) => (
            <Box component={Paper} mb={3} key={banner._id}>
              <Row className="banner">
                <Col xs="8" className="d-flex flex-column align-items-start">
                  <p>Deep Link: {banner.deepLink}</p>
                  <div>
                    <img src={banner.image} />
                  </div>
                </Col>
                <Col xs="4" className="d-flex justify-content-between">
                  {/* <p>{banner.createdAt}</p> */}
                  <Checkbox
                    label="Active"
                    checked={banner.status}
                    onClick={() => toggleStatus(i)}
                  />
                </Col>
                <span onClick={() => removeBanner(banner)} className="remove-icon">
                  <img src="/image/close.svg" alt="close modal" />
                </span>
              </Row>
            </Box>
          ))}
        </Box>
      </Box>
      <Modal
        open={openAddBannerModal}
        onClose={() => setOpenAddBannerModal(false)}
        styles={{
          width: '70%',
          height: '100%',
        }}
      >
        <CreateBannerForm onSubmit={onBannerAdded} onCancel={() => setOpenAddBannerModal(false)} />
      </Modal>
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => ({
  openToast: (message) => dispatch(openToastState({ message })),
})

export default connect(null, mapDispatchToProps)(Banners)