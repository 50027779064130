import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateSubcategory } from 'services/categories'
import { useForm } from "react-hook-form";
import {
  useLocation
} from "react-router-dom"

import { makeStyles } from '@material-ui/core/styles';
import {
  TableBody,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';

import Button from 'components/common/button'
import Input from 'components/common/input'
import Dropdown from 'components/common/dropdown'
import {
  Table,
  StyledTableThCell,
  StyledTableCell,
  StyledTableRow,
} from 'components/common/table'
import CategoryList from '../Inventory/Filter/components/category-list'

import { openToast as openToastState } from 'reducers/toast/actions'
import { loadCates } from 'utils/state_init'

import './_styles.scss'

const useStyles = makeStyles({
  actionColumn: {
    width: '200px',
  }
});

const taxUnitObj = {
  percent: 'Percent',
  fixed: 'Dollar'
}
const taxUnits = Object.entries(taxUnitObj).map(([value, name]) => ({ value, name }))

const SubcategoryRow = ({
  subcategory,
  index,
  onUpdate,
}) => {
  const [editing, setEditing] = useState(false)
  const editSubcategory = (subcategory) => {
    onUpdate(subcategory)
    setEditing(false)
  }
  return !editing ? (
    <SubcategoryRowNotEditing
      subcategory={subcategory}
      index={index}
      setEditing={setEditing}
    />
  ) : (
    <SubcategoryRowEditing
      subcategory={subcategory}
      index={index}
      setEditing={setEditing}
      onUpdate={editSubcategory}
    />
  )
}
const SubcategoryRowNotEditing = ({
  subcategory,
  index,
  setEditing,
  ...props
}) => {
  return (
    <StyledTableRow key={subcategory._id} {...props}>
      <StyledTableCell component='th' scope='row'>
        {index + 1}
      </StyledTableCell>
      <StyledTableCell align='left'>{subcategory.label}</StyledTableCell>
      <StyledTableCell align='left'>{subcategory.taxUnitName}</StyledTableCell>
      <StyledTableCell align='left'>{subcategory.tax}</StyledTableCell>
      <StyledTableCell align='left'>
        <div className='actions'>
          <Button onClick={() => setEditing(true)} className='outlined'>
            Edit
          </Button>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  )
}
const SubcategoryRowEditing = ({
  subcategory,
  index,
  setEditing,
  onUpdate,
}) => {
  const { control } = useForm({
    defaultValues: {
      taxUnit: subcategory.taxUnit,
    }
  });
  const [tempCategory, setTempCategory] = useState({ ...subcategory })
  const updateField = (field, value) => {
    if (field === 'taxUnit') {
      tempCategory.taxUnitName = taxUnitObj[value]
    }
    tempCategory[field] = value
    setTempCategory({ ...tempCategory })
  }
  return (
    <StyledTableRow key={subcategory._id}>
      <StyledTableCell component='th' scope='row'>
        {index + 1}
      </StyledTableCell>
      <StyledTableCell align='left'>
        <Input
          type='text'
          value={tempCategory.label}
          onChange={e => updateField('label', e.target.value)}
        />
      </StyledTableCell>
      <StyledTableCell align='left'>
        <Dropdown
          handleChange={(event) => updateField('taxUnit', event.target.value)}
          items={taxUnits}
          control={control}
          name='taxUnit'
          value={tempCategory.taxUnit}
        />
      </StyledTableCell>
      <StyledTableCell align='left'>
        <Input
          type='text'
          value={tempCategory.tax}
          onChange={e => updateField('tax', e.target.value)}
        />
      </StyledTableCell>
      <StyledTableCell align='left'>
        <div className='actions'>
          <Button onClick={() => onUpdate(tempCategory)}>Update</Button>
          <Button className='outlined' onClick={() => setEditing(false)}>Cancel</Button>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  )
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Subcategories({
  subcategories,
  openToast,
  dispatch,
}) {
  const query = useQuery()
  const [categoryQuery] = useState(query.get('category'))
  const classes = useStyles()
  const onSelectCategoryId = async (categoryId) => {
    if (categoryId) {
      const selectedSubcategories = subcategories.filter(s => s.category === categoryId)
      setSelectedSubcates(selectedSubcategories)
    } else {
      setSelectedSubcates(subcategories)
    }
  }
  const [selectedSubcates, setSelectedSubcates] = useState([])
  useEffect(() => {
    let selectedSubcates = []
    if (categoryQuery) {
      selectedSubcates = subcategories.filter(subcate => subcate.category === categoryQuery)
    }
    renameTaxUnits(selectedSubcates)
    setSelectedSubcates(selectedSubcates)
  }, [subcategories, categoryQuery])
  const updateSubcategoryDetail = async subcategory => {
    const data = {
      id: subcategory._id,
      label: subcategory.label,
      taxUnit: subcategory.taxUnit,
      tax: subcategory.tax,
    }
    try {
      await updateSubcategory(data)
    } catch (err) {
      return console.log(err)
    }
    openToast('Update Successfully', 'success')
    loadCates(dispatch)
  }
  const renameTaxUnits = (subcategories = []) => {
    subcategories.forEach(subcate => {
      if (subcate.taxUnit) {
        subcate.taxUnitName = taxUnitObj[subcate.taxUnit]
      }
    })
  }
  return (
    <Box className='open-order-page'>
      <Box component='div' mb={2} px={3}>
        <Box
          component='div'
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          height='100%'
          py={2.5}
        >
          <Box
            component='div'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            className='font-26 text-dark-blue'
          >
            Subcategories
          </Box>
        </Box>
        <Box mb={2}>
          <CategoryList
            onSelectItem={cate => onSelectCategoryId(cate?._id)}
            selectedCategoryId={categoryQuery}
          />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableThCell />
              <StyledTableThCell align='left'>LABEL</StyledTableThCell>
              <StyledTableThCell align='left'>TAX UNIT</StyledTableThCell>
              <StyledTableThCell align='left'>TAX</StyledTableThCell>
              <StyledTableThCell align='left' className={classes.actionColumn} />
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedSubcates.map((subcate, i) => (
              <SubcategoryRow
                subcategory={subcate}
                index={i}
                onUpdate={updateSubcategoryDetail}
                key={subcate._id}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ category }) => ({
  categories: category.categories,
  subcategories: category.subcategories,
})
const mapDispatchToProps = (dispatch) => ({
  openToast: (message, type) => dispatch(openToastState({ message, type })),
  dispatch,
})
export default connect(mapStateToProps, mapDispatchToProps)(Subcategories)
