import React, { useState } from "react";
import { connect } from 'react-redux'
import {
  Box,
} from "@material-ui/core"
import _ from 'lodash'
import CreateProductForm from './components/CreateEditProductForm'

import './_styles.scss'

import { createProduct } from 'services/products'
import { setCategories as setCategoriesAction } from 'reducers/category/actions'

const CreateProduct = ({
  categoriesState,
  subcategoriesState,
  onDone,
}) => {
  const [onSubmitting, setOnSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')

  const [categories] = useState(categoriesState)
  const [subcategories] = useState(subcategoriesState)

  const submit = async data => {
    setOnSubmitting(true)
    let res
    try {
      res = (await createProduct({
        ...data,
      })).data
    } catch (err) {
      setOnSubmitting(false)
      return setSubmitError(err.response.data.err)
    }
    if (res.result !== 'success') {
      setOnSubmitting(false)
      return setSubmitError(res.err)
    }
    onDone(true)
  }

  return (
    <Box component='div' mb={2} className='add-product'>
      <Box
        component='div'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        height='100%'
        py={2.5}
      >
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          className='font-26 text-dark-blue'
        >
          Create Product
        </Box>
        <CreateProductForm
          categories={categories}
          subcategories={subcategories}
          onSubmit={submit}
          onCancel={() => onDone(false)}
          isCreate={true}
          key={`create_product`}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ category }) => ({
  categoriesState: category.categories,
  subcategoriesState: category.subcategories,
})
const mapDispatchToProps = dispatch => ({
  setCategoriesState: categories => dispatch(setCategoriesAction(categories))
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct)
