import React from 'react'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router'

import Header from 'components/Navigation/Header/Header'
import Sidenav from 'components/Navigation/Sidenav/Sidenav'
import Login from 'components/Initial/Login/Login'
import history from 'utils/history'
import Loader from 'components/Navigation/Loader/Loader'
import AuthenticatedContainer from 'components/Containers/Authenticated'
import Snackbar from 'components/common/snackbar'

import { loadUser } from 'utils/state_init'
import { setAxiosDispatch } from 'utils/axios'

class App extends React.Component {
  constructor(props) {
    super(props)
    loadUser(props.dispatch)
    setAxiosDispatch(props.dispatch)
  }
  render() {
    return (
      <Router history={history}>
        <>
          <Loader />
          <Switch>
            <Route path='/login' component={Login} strict></Route>
            <Route path='/'>
              <Sidenav />
              <main className='container-fluid'>
                <Header />
                <AuthenticatedContainer />
                <Snackbar />
              </main>
            </Route>
          </Switch>
        </>
      </Router>
    )
  }
}

export default connect()(App)
