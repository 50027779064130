import { SET_CATEGORIES, SET_SUBCATEGORIES } from './constants'


export const setCategories = (categories) => {
  return {
    type: SET_CATEGORIES,
    payload: categories
  }
}

export const setSubcategories = (subcategories) => {
  return {
    type: SET_SUBCATEGORIES,
    payload: subcategories
  }
}