import React, {useState, useRef, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import './Input.scss';

const Input = (props) => {
    const {
        label, errorMessage, type, step,
        error, refInput, placeholder,
        name, value, onChange,
        disabled,
    } = props;
    const refInputBlock = useRef();
    const [activeLabel, setActiveLabel] = useState(false);

    useEffect(() => {
        if (value) showActiveLabel()
    }, [value])
    const showActiveLabel = () => {
        refInputBlock.current.children[0].focus();
        setActiveLabel(true);
    }
    const closeActiveLabel = () => {
        if (!value) {
            setActiveLabel(false);
        }
    }
    const onBlur = () => {
        if (!value) {
            setActiveLabel(false);
        }
    }
    return (
        <OutsideClickHandler
            onOutsideClick={closeActiveLabel}
        >
            <div className="Input">
                <label onClick={showActiveLabel} className={`Input-label ${activeLabel ? 'Input-labelFocused' : ''}`}>
                    {label}
                </label>
                <div ref={refInputBlock}>
                    <input
                        className={`Input-field ${error ? 'Input-fieldError' : ''}`}
                        placeholder={placeholder}
                        type={type}
                        ref={refInput}
                        name={name}
                        onFocus={showActiveLabel}
                        onBlur={onBlur}
                        onChange={onChange || undefined}
                        step={step}
                        autoComplete="off"
                        disabled={disabled}
                        value={value}
                    />
                </div>
                {error && <div className="Input-error">{errorMessage}</div>}
            </div>
        </OutsideClickHandler>
    );
}

export default Input;
