import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableCell, TableRow, Checkbox, Button } from "@material-ui/core";

// The `withStyles()` higher-order component is injecting a `classes`

/*****  Catalog Table Cell *****/
export const CatalogTableCell = withStyles({
  head: {
    backgroundColor: "#edf1f4",
    color: "#7b8a96",
    fontSize: 13,
    fontWeight: 700,
    textTransform: "uppercase",
    border: 0,
  },
  body: {
    fontSize: 14,
    color: "#4d4f5c",
    border: 0,
    fontFamily: "'Poppins', sans-serif",
  },
})(TableCell);

/*****  Catalog Table Row *****/
export const CatalogTableRow = withStyles((theme) => ({
  root: {
    border: 0,
    height: 69,
    // display: "block",
    marginBottom: 8,
    "&:first-child": {
      height: 45,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "#f7f9fa",
    },
  },
}))(TableRow);

export const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#3fb618',
    },
  },
  checked: {},
})(Checkbox)

export const SubmitButton = withStyles({
  root: {
    color: '#fff',
    background: '#3fb618',
    borderRadius: '40px',
    width: '155px',
    height: '44px',
    fontSize: '13px',
    fontWeight: 600,
  },
})(Button)

export const AddProductButton = withStyles({
  root: {
    color: '#3fb618',
    borderRadius: '40px',
    border: '1.5px solid #3fb618',
    width: '155px',
    height: '44px',
    fontSize: '13px',
    fontWeight: 600,
    marginLeft: '20px',
  },
})(Button)
