import React from 'react';

import './Input.scss';

import SearchIcon from './icons/search.svg'

const SearchInput = ({ label,
  type,
  error,
  refInput,
  placeholder,
  name,
}) => {
  return (
    <div className='Input-search'>
      <img src={SearchIcon} alt='search' />
      <input
        className={`${error ? 'Input-fieldError' : ''}`}
        placeholder={placeholder || 'Search'}
        type={type}
        ref={refInput}
        name={name} />
    </div>
  )
}

export default SearchInput;
