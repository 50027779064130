import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Menu, MenuItem } from '@material-ui/core'
import _ from 'lodash'
import {
  Done as DoneIcon,
  HourglassEmpty as HourglassEmptyIcon,
} from '@material-ui/icons'
import './_styles.scss';
import { Container, Row, Col, Button, Form, FormControl } from 'react-bootstrap';
import Input from 'components/common/input'
import Dropdown from 'components/common/dropdown'
import userSample from '../../../assets/images/user-upload.png';

import Report from './components/report'

import {
  getStore,
  getReport,
  updateStore,
} from 'services/stores'

import { getStoreStatusContent } from 'utils/helpers'

const statuses = [
  {
    name: 'Approved',
    value: 1,
  },
  {
    name: 'Rejected',
    value: -1,
  }
]

const reportPeriods = [
  {
    name: 'Day',
    value: 'day',
  },
  {
    name: 'Month',
    value: 'month',
  },
  {
    name: 'Year',
    value: 'year',
  }
]

const StoreDetail = ({ match }) => {
  const [store, setStore] = useState({})
  const [report, setReport] = useState()
  const [user, setUser] = useState({})
  const [selectedPeriod, setSelectedPeriod] = useState(reportPeriods[0].value)
  const [anchorEl, setAnchorEl] = useState(null)

  const profileImgRef = useRef(null)

  const { register, watch, setValue, control, errors } = useForm({
    defaultValues: {
      reportPeriod: reportPeriods[0].value
    }
  })

  useEffect(() => {
    loadStore(match.params.id)
  }, [])

  const loadStore = async (id) => {
    // get store
    let store
    try {
      store = (await getStore(id)).data.data
    } catch (err) {
      return console.error(err)
    }
    setStore(store)
    setUser(store.businessUserId || {})
    setStateFees(store)
    // get report
    let report
    try {
      report = (await getReport(id)).data.data
    } catch (err) {
      return console.error(err)
    }
    setReport(report)
  }

  const setStateFees = store => {
    setValue('fixedFee', store.fixedFee)
    setValue('percentageFee', store.percentageFee)
    setValue('fixedPickupFee', store.fixedPickupFee)
    setValue('percentagePickupFee', store.percentagePickupFee)
  }
  const setFees = async () => {
    const updatedStore = await updateStore(store._id, {
      fixedFee: watch('fixedFee'),
      percentageFee: watch('percentageFee'),
      fixedPickupFee: watch('fixedPickupFee'),
      percentagePickupFee: watch('percentagePickupFee'),
    })
  }

  const updateStatus = async status => {
    setAnchorEl(null)
    let res
    try {
      res = (await updateStore(store._id, {
        status,
      })).data
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      return console.error(err)
    }
    store.status = res.data.status
    setStore({ ...store })
  }

  const onChangePeriod = async value => {
    const period = value.target.value
    // get report
    let report
    try {
      report = (await getReport(store._id, period)).data.data
    } catch (err) {
      return console.error(err)
    }
    setReport(report)
  }

  const updatePartnership = async isPartner => {
    setAnchorEl(null)
    let res
    try {
      res = (await updateStore(store._id, {
        isPartner,
      })).data
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      return console.error(err)
    }
    store.isPartner = res.data.isPartner
    setStore({ ...store })
  }
  return (
    <div className="main-page-wrapper px-lg-40 pb-5">
      <Container fluid>
        <div className="page-title d-flex py-3">{store.name}</div>
        <div className="my-profile-container">
          <div className="my-profile-inner">
            <Row className='mb-3'>
              <div className="d-flex justify-content-between w-100 mb-4">
                {!store.stripeAccount ? (
                  <h3 className="status-approved w-100">Bank Linked <DoneIcon /></h3>
                ) : (
                    <h3 className="status-pending w-100">Bank Not Link <HourglassEmptyIcon /></h3>
                  )}
                <div className='actions-group'>
                  {store.isPartner ? (<Button
                    className="btn-danger"
                    onClick={() => updatePartnership(false)}
                  >
                    Revoke Partner
                  </Button>) : (<Button
                    className="btn-save"
                    onClick={() => updatePartnership(true)}
                  >
                    Make Partnership
                  </Button>)}
                  <div className="dropdown">
                    <button
                      className={`btn btn-secondary dropdown-toggle ${getStoreStatusContent(store.status).toUpperCase()}`}
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={event => setAnchorEl(event.currentTarget)}
                    >
                      {getStoreStatusContent(store.status)}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                      >
                        <path
                          id="Path_360"
                          data-name="Path 360"
                          d="M6.439,9.486,2.6,5.606,3.6,4.6,6.439,7.474,9.282,4.6l1,1.006Z"
                          transform="translate(-2.6 -4.6)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      {statuses.map(status => (
                        <MenuItem onClick={() => updateStatus(status.value)}>{status.name}</MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col xs="12" xl="4" className="pr-lg-2">
                <div className="user-upload-wrapper mb-5 mb-xl-0">
                  <div className="d-flex justify-content-center mb-4">
                    <img
                      src={store.image || userSample}
                      alt="User"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </Col>
              <Col xs="12" xl={{ size: 7, offset: 1 }}>
                <div className="myprofile-frm-right px-3 px-xl-0">
                  <Form>
                    <Form.Group>
                      <Row>
                        <Col xs="6">
                          <FormControl
                            placeholder="First name"
                            value={user.firstName}
                            disabled
                          />
                        </Col>
                        <Col xs="6">
                          <Form.Control
                            placeholder="Last name"
                            value={user.lastName}
                            disabled
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        value={user.email}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        placeholder="Phone Number"
                        value={user.phoneNumber}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        placeholder="Full Address"
                        value={user.address}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        placeholder="Store Name"
                        value={store.name}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        placeholder="Store Type"
                        value={store.type}
                        disabled
                      />
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="myprofile-frm-right px-3 px-xl-0">
                  <Form>
                    <Form.Group>
                      <Row className="mb-2">
                        <Col xs={{
                          offset: 5,
                          span: 7
                        }}
                          className="text-center"
                        >
                          Store Hours
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs={{
                          offset: 5,
                          span: 7
                        }}>
                          <Row>
                            <Col xs="6">
                              Opening Time
                            </Col>
                            <Col xs="6">
                              Closing Time
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="time-input mb-3">
                        <Col xs="5" className="d-flex align-items-center mb-0 justify-content-end">
                          Monday
                        </Col>
                        <Col xs="7">
                          <Row>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Opening Time"
                                value={store.openTime?.mon?.from}
                                disabled
                              />
                            </Col>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Closing Time"
                                value={store.openTime?.mon?.to}
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="time-input mb-3">
                        <Col xs="5" className="d-flex align-items-center mb-0 justify-content-end">
                          Tuesday
                        </Col>
                        <Col xs="7">
                          <Row>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Opening Time"
                                value={store.openTime?.tue?.from}
                                disabled
                              />
                            </Col>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Closing Time"
                                value={store.openTime?.tue?.to}
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="time-input mb-3">
                        <Col xs="5" className="d-flex align-items-center mb-0 justify-content-end">
                          Wednesday
                        </Col>
                        <Col xs="7">
                          <Row>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Opening Time"
                                value={store.openTime?.wed?.from}
                                disabled
                              />
                            </Col>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Closing Time"
                                value={store.openTime?.wed?.to}
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="time-input mb-3">
                        <Col xs="5" className="d-flex align-items-center mb-0 justify-content-end">
                          Thursday
                        </Col>
                        <Col xs="7">
                          <Row>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Opening Time"
                                value={store.openTime?.thu?.from}
                                disabled
                              />
                            </Col>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Closing Time"
                                value={store.openTime?.thu?.to}
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="time-input mb-3">
                        <Col xs="5" className="d-flex align-items-center mb-0 justify-content-end">
                          Friday
                        </Col>
                        <Col xs="7">
                          <Row>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Opening Time"
                                value={store.openTime?.fri?.from}
                                disabled
                              />
                            </Col>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Closing Time"
                                value={store.openTime?.fri?.to}
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="time-input mb-3">
                        <Col xs="5" className="d-flex align-items-center mb-0 justify-content-end">
                          Saturday
                        </Col>
                        <Col xs="7">
                          <Row>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Opening Time"
                                value={store.openTime?.sat?.from}
                                disabled
                              />
                            </Col>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Closing Time"
                                value={store.openTime?.sat?.to}
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="time-input mb-3">
                        <Col xs="5" className="d-flex align-items-center mb-0 justify-content-end">
                          Sunday
                        </Col>
                        <Col xs="7">
                          <Row>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Opening Time"
                                value={store.openTime?.sun?.from}
                                disabled
                              />
                            </Col>
                            <Col xs="6">
                              <Form.Control
                                type="time"
                                placeholder="Closing Time"
                                value={store.openTime?.sun?.to}
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form>
                  <Form.Group>
                    <Row>
                      <Col className='text-center mb-4'>
                        Summary
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4">
                        <Input
                          label="Fixed Fee ($)"
                          type="number"
                          error={errors.fixedFee}
                          refInput={register({
                            required: true,
                          })}
                          value={watch('fixedFee')}
                          name="fixedFee"
                        />
                      </Col>
                      <Col xs="4">
                        <Input
                          label="Percentage Fee (%)"
                          type="number"
                          error={errors.percentageFee}
                          refInput={register({
                            required: true,
                          })}
                          value={watch('percentageFee')}
                          name="percentageFee"
                        />
                      </Col>
                      <Col xs="4">
                        <Col xs="4">
                          <Input
                            label="C/C Fee"
                            type="text"
                            refInput={register({
                              required: true,
                            })}
                            value={store.ccFee}
                            disabled={true}
                          />
                        </Col>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xs="6">
                        <Input
                          label="Fixed Pickup Fee ($)"
                          type="number"
                          error={errors.fixedPickupFee}
                          refInput={register({
                            required: true,
                          })}
                          value={watch('fixedPickupFee')}
                          name="fixedPickupFee"
                        />
                      </Col>
                      <Col xs="6">
                        <Input
                          label="Percentage Pickup Fee (%)"
                          type="number"
                          error={errors.percentagePickupFee}
                          refInput={register({
                            required: true,
                          })}
                          value={watch('percentagePickupFee')}
                          name="percentagePickupFee"
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row className='mt-3'>
              <div className='m-auto w-50'>
                <Dropdown
                  label="Period"
                  name='reportPeriod'
                  handleChange={onChangePeriod}
                  items={reportPeriods}
                  control={control}
                  value={watch('reportPeriod')}
                />
                <Report report={report} />
              </div>
            </Row>
            <div className='action-groups'>
              <Button
                className="btn-save"
                onClick={setFees}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default StoreDetail
