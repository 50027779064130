import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getShoppers, updateShopperDetail } from 'services/shoppers'
import { useForm } from "react-hook-form";

import { makeStyles } from '@material-ui/core/styles';
import {
  TableBody,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';

import Button from 'components/common/button'
import Input from 'components/common/input'
import Dropdown from 'components/common/dropdown'
import {
  Table,
  StyledTableThCell,
  StyledTableCell,
  StyledTableRow,
} from 'components/common/table'

import { openToast as openToastState } from 'reducers/toast/actions'

import './_styles.scss'

const useStyles = makeStyles({
  actionColumn: {
    width: '200px',
  }
});

const ShopperRow = ({
  shopper,
  index,
  onUpdate,
  buildings,
}) => {
  const [editing, setEditing] = useState(false)
  const editShopper = (shopper) => {
    onUpdate(shopper)
    setEditing(false)
  }
  return !editing ? (
    <ShopperRowNotEditing
      shopper={shopper}
      index={index}
      setEditing={setEditing}
    />
  ) : (
    <ShopperRowEditing
      shopper={shopper}
      index={index}
      buildings={buildings}
      setEditing={setEditing}
      onUpdate={editShopper}
    />
  )
}
const ShopperRowNotEditing = ({
  shopper,
  index,
  setEditing,
}) => {
  return (
    <StyledTableRow key={shopper._id}>
      <StyledTableCell component='th' scope='row'>
        {index + 1}
      </StyledTableCell>
      <StyledTableCell align='left'>{shopper.firstName}</StyledTableCell>
      <StyledTableCell align='left'>{shopper.lastName}</StyledTableCell>
      <StyledTableCell align='left'>{shopper.email}</StyledTableCell>
      <StyledTableCell align='left'>{shopper.username}</StyledTableCell>
      <StyledTableCell align='left'>{shopper.phoneNumber}</StyledTableCell>
      <StyledTableCell align='left'>{shopper.buildingName}</StyledTableCell>
      <StyledTableCell align='left'>
        <div className='actions'>
          <Button onClick={() => setEditing(true)} className='outlined'>
            Edit
          </Button>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  )
}
const ShopperRowEditing = ({
  shopper,
  index,
  buildings,
  setEditing,
  onUpdate,
}) => {
  const { control } = useForm({
    defaultValues: {
      building: shopper.building,
    }
  });
  const [tempShopper, setTempShopper] = useState({ ...shopper })
  const updateField = (field, value) => {
    if (field === 'building') {
      tempShopper.building = buildings[value]?._id
      tempShopper.buildingName = buildings[value]?.name
    } else {
      tempShopper[field] = value
    }
    setTempShopper({ ...tempShopper })
  }
  return (
    <StyledTableRow key={shopper._id}>
      <StyledTableCell component='th' scope='row'>
        {index + 1}
      </StyledTableCell>
      <StyledTableCell align='left'>
        <Input
          type='text'
          value={tempShopper.firstName}
          onChange={e => updateField('firstName', e.target.value)}
        />
      </StyledTableCell>
      <StyledTableCell align='left'>
        <Input
          type='text'
          value={tempShopper.lastName}
          onChange={e => updateField('lastName', e.target.value)}
        />
      </StyledTableCell>
      <StyledTableCell align='left'>{tempShopper.email}</StyledTableCell>
      <StyledTableCell align='left'>{tempShopper.username}</StyledTableCell>
      <StyledTableCell align='left'>{tempShopper.phoneNumber}</StyledTableCell>
      <StyledTableCell align='left'>
        <Dropdown
          handleChange={(event) => updateField('building', event.target.value)}
          items={[{
            value: undefined,
            name: 'unset',
          }].concat(Object.values(buildings).map(building => ({
            value: building._id,
            name: building.name,
          })))}
          control={control}
          name='building'
          value={tempShopper.building}
        />
      </StyledTableCell>
      <StyledTableCell align='left'>
        <div className='actions'>
          <Button onClick={() => onUpdate(tempShopper)}>Update</Button>
          <Button className='outlined' onClick={() => setEditing(false)}>Cancel</Button>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  )
}

function Shoppers({
  buildings,
  openToast,
}) {
  const classes = useStyles()
  const [shoppers, setShoppers] = useState([])
  useEffect(() => {
    if (buildings) loadShoppers()
  }, [buildings])
  const loadShoppers = async () => {
    const shoppers = (await getShoppers()).data.data
    renameShoppersBulding(shoppers)
    setShoppers(shoppers)
  }
  const renameShoppersBulding = shoppers => {
    shoppers.forEach(shopper => {
      if (shopper.building) {
        shopper.buildingName = buildings[shopper.building]?.name
      }
    })
  }
  const updateShopper = async shopper => {
    const data = {
      _id: shopper._id,
      firstName: shopper.firstName,
      lastName: shopper.lastName,
      phoneNumber: shopper.phoneNumber,
      building: shopper.building,
    }
    try {
      await updateShopperDetail(data)
    } catch (err) {
      return console.log(err)
    }
    openToast('Update Successfully', 'success')
    // update shopper in list
    const shopperIndex = shoppers.findIndex(s => s._id === shopper._id)
    if (shopperIndex !== -1) {
      shoppers[shopperIndex] = shopper
      setShoppers([...shoppers])
    }
  }
  return (
    <Box className='open-order-page'>
      <Box component='div' mb={2} px={3}>
        <Box
          component='div'
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          height='100%'
          py={2.5}
        >
          <Box
            component='div'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            className='font-26 text-dark-blue'
          >
            Shoppers
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableThCell />
              <StyledTableThCell align='left'>FIRST NAME</StyledTableThCell>
              <StyledTableThCell align='left'>LAST NAME</StyledTableThCell>
              <StyledTableThCell align='left'>EMAIL</StyledTableThCell>
              <StyledTableThCell align='left'>USERNAME</StyledTableThCell>
              <StyledTableThCell align='left'>PHONE NUMBER</StyledTableThCell>
              <StyledTableThCell align='left'>BUILDING</StyledTableThCell>
              <StyledTableThCell align='left' className={classes.actionColumn} />
            </TableRow>
          </TableHead>
          <TableBody>
            {shoppers.map((shopper, i) => (
              <ShopperRow
                shopper={shopper}
                index={i}
                onUpdate={updateShopper}
                buildings={buildings}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ buildings }) => ({
  buildings: buildings.buildingsObj,
})
const mapDispatchToProps = (dispatch) => ({
  openToast: (message, type) => dispatch(openToastState({ message, type })),
})
export default connect(mapStateToProps, mapDispatchToProps)(Shoppers)
