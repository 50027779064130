import axios from 'utils/axios'
import { store } from 'store'
import { CLEAR_USER } from 'reducers/user/constants'
import { openToast } from 'reducers/toast/actions'

export default (option) => {
  return axios(option).catch(err => {
    if (err.response.status === 401) {
      store.dispatch({
        type: CLEAR_USER,
      })
    }
    throw err
  }).then(res => {
    if (res.data?.result !== 'success') {
      store.dispatch(openToast({
        message: res.data?.err
      }))
      throw new Error(res.data?.err)
    }
    return res
  })
}