import React from 'react'

import './styles.scss'

const Button = (props) => {
  const { children, onClick, type, disabled } = props
  return (
    <button
      className={`Button ${props.className || ''}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <div className='Button-text'>{children}</div>
    </button>
  )
}

export default Button
