import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router'

import Shoppers from 'components/Main/Shoppers/Shoppers'
import StoreDetail from 'components/Main/StoreDetail'
import Banners from 'components/Main/Banners/Banners'
import Inventory from 'components/Main/Inventory/Inventory'
import Categories from 'components/Main/Categories/Categories'
import Subcategories from 'components/Main/Subcategories/Subcategories'
import NotFound from 'components/Navigation/NotFound'

import { loadLoggedInData } from 'utils/state_init'

class AuthenticatedContainer extends React.Component {
  constructor(props) {
    super(props)
    loadLoggedInData(props.dispatch)
  }
  render() {
    const {
      user,
    } = this.props
    if (!user || !user.token) return <Redirect to='/login' />
    return (
      <Switch>
        <Route
          path='/stores/:id'
          component={StoreDetail}
          strict
        ></Route>
        <Route path='/shoppers' component={Shoppers} strict></Route>
        <Route path='/banners' component={Banners} strict></Route>
        <Route path='/inventory' component={Inventory} strict></Route>
        <Route path='/categories' component={Categories} strict></Route>
        <Route path='/subcategories' component={Subcategories} strict></Route>
        <Route path='/' exact render={() => <Redirect to='inventory' />}></Route>
        <Route
          path='*'
          component={NotFound}
          exact={true}
          strict
        ></Route>
      </Switch>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(AuthenticatedContainer)
