import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TableContainer,
  Table,
  Paper,
} from '@material-ui/core'

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
  tableContainer: {
    padding: 15,
  },
});

export default ({ children }) => {
  const classes = useStyles()
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        {children}
      </Table>
    </TableContainer>
  )
}
