import request from './base'

export const getCategories = () => {
  return request({ url: '/categories' })
}

export const createCategory = (label) => {
  return request({
    method: 'POST',
    url: `/categories`,
    data: {
      label,
    },
  })
}

export const createSubcategory = (categoryId, label) => {
  return request({
    method: 'POST',
    url: `/${categoryId}/subcategories`,
    data: {
      label,
    },
  })
}

export const updateCategory = ({ id, ...data }) => {
  return request({
    method: 'PUT',
    url: `/categories/${id}`,
    data,
  })
}

export const updateSubcategory = ({ id, ...data }) => {
  return request({
    method: 'PUT',
    url: `/subcategories/${id}`,
    data,
  })
}
