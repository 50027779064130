import { withStyles } from '@material-ui/core/styles'
import {
  TableRow
} from '@material-ui/core'

export default withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fa',
    },
    color: '#4d4f5c',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dedede',
    }
  },
}))(TableRow)
