import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'

import './dropdown.scss';

const useStyles = makeStyles({
  selectMenu: props => props.selectMenu,
  root: props => props.root,
})

const Dropdown = (props) => {
  const {
    label, name, value, items, handleChange, control,
    refInput, error, errorMessage, required,
    // styling
    classes: classesProps,
    styles,
  } = props;
  const refInputBlock = useRef();
  const [activeLabel, setActiveLabel] = useState(!!value);

  const classes = useStyles(classesProps)

  useEffect(() => {
    if (value) showActiveLabel()
  }, [value])
  const showActiveLabel = () => {
    refInputBlock.current.children[0].focus();
    setActiveLabel(true);
  }
  const closeActiveLabel = () => {
    if (!value) {
      setActiveLabel(false);
    }
  }
  const onBlur = () => {
    if (!value) {
      setActiveLabel(false);
    }
  }
  const onChange = event => {
    handleChange && handleChange(event)
    return event.target.value
  }
  return (
    <OutsideClickHandler
      onOutsideClick={closeActiveLabel}
    >
      <div className='Dropdown' ref={refInputBlock} style={styles}>
        <InputLabel className={`Dropdown-label ${activeLabel ? 'Dropdown-labelFocused' : ''}`}>
          {label}
        </InputLabel>
        <Controller
          as={
            <Select
              className={`Dropdown-field ${error ? 'Dropdown-fieldError' : ''}`}
              onFocus={showActiveLabel}
              onBlur={onBlur}
              classes={{
                selectMenu: classes.selectMenu,
                root: classes.root,
              }}
              displayEmpty
            // onChange={handleChange}
            >
              <MenuItem value=''></MenuItem>
              {items.map(item => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          }
          name={name}
          control={control}
          ref={refInput}
          rules={{ required }}
          defaultValue=''
          onChange={([event]) => onChange(event)}
        >
        </Controller>
        {error && <div className='Input-error'>{errorMessage}</div>}
      </div>
    </OutsideClickHandler>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  items: PropTypes.array.isRequired,
  control: PropTypes.isRequired,
}

export default Dropdown;
