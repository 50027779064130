import React from 'react'

import ReportRow from './report-row'

import './_styles.scss'

export default ({ report }) => {
  if (!report) return <React.Fragment />
  return (
    <div className='report-container'>
      <ReportRow title='Total sold' value={`$${report.totalCaptured || report.totalAmount}`} />
      <ReportRow title='#Transactions' value={report.totalOrder} />
      <ReportRow title='Total Fixed Fee' value={`$${report.totalFixedFee}`} />
      <ReportRow title='Total Percentage Fee' value={`$${report.totalPercentageFee}`} />
      <ReportRow title='Total CC Fee' value={`$${report.totalCCFee}`} />
    </div>
  )
}