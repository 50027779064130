import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Box } from '@material-ui/core'
import Button from 'components/common/button'
import Input from 'components/common/input'

import ImageCropper from 'components/common/image-cropper'

// import './_styles.scss'

import { upload } from 'services/file'
import { createBanner } from 'services/banners'

const CreateBannerForm = ({
  onSubmit,
  onCancel,
}) => {
  const { handleSubmit } = useForm({})
  const [croppedImage, setCroppedImage] = useState({
    blob: '',
    base64: '',
  })
  const [deepLink, setDeepLink] = useState('')
  const [onSubmitting, setOnSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')

  const onDeepLinkChange = (event) => {
    setDeepLink(event.target.value)
  }

  /**
   * @param {object} img blob, base64
   */
  const onCroppedImg = (img) => {
    setCroppedImage(img)
  }

  const submit = async () => {
    let image
    // user upload image
    if (croppedImage.base64) {
      // upload
      let uploadImgRes
      try {
        const res = await upload(croppedImage.base64)
        uploadImgRes = res.data
      } catch (err) {
        return setSubmitError(err.response.data.err)
      }
      if (uploadImgRes.result !== 'success') {
        return setSubmitError(uploadImgRes.err)
      }
      image = uploadImgRes.file._id
    }
    await createBanner({ image, deepLink })
    // dismiss modal
    onSubmit()
  }

  return (
    <Box component="div" mb={2} className="upload-banner">
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
        py={2.5}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className="font-26 text-dark-blue"
        >
          Upload Banner
        </Box>
        <div className="i-f-container">
          <div className="i-f-form-img">
            <ImageCropper
              onCroppedImg={onCroppedImg}
              btnClass="outlined"
              setImg={croppedImage.blob}
            />
          </div>
          <form onSubmit={handleSubmit(submit)} className="i-f-form">
            <Input label="Deep link" type="text" onChange={onDeepLinkChange} />
            {submitError && <div className="Input-error">{submitError}</div>}
            <div className="i-f-form-row-submit">
              <Button className="outlined" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" className="submit-btn" disabled={onSubmitting}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </Box>
    </Box>
  )
}

export default CreateBannerForm
