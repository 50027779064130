import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import {
  Box,
} from "@material-ui/core"
import { useForm } from 'react-hook-form'

import Dropdown from 'components/common/dropdown'
import Button from 'components/common/button'
import Input from 'components/common/input'

import './_styles.scss'

import { createSubcategory } from 'services/categories'

const CreateSubcategory = ({
  categories: categoriesState,
  subcategories,
  store,
  onDone,
  selectedCategory,
  dispatch,
}) => {
  const { handleSubmit, register, watch, control, errors, setValue } = useForm()
  const [onSubmitting, setOnSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const newCates = categoriesState.map(c => ({
      ...c,
      value: c._id
    }))
    setCategories(newCates)
    if (newCates[0]) {
      setValue('category', selectedCategory || newCates[0].value)
    }
  }, [categoriesState])

  const submit = async () => {
    const subcategory = watch('newSubcategory')
    const category = watch('category')
    if (!category) return setSubmitError('Please select category')
    if (!subcategory) return setSubmitError('Please enter subcategory')
    let res
    try {
      res = (await createSubcategory(store._id, category, subcategory)).data
    } catch (err) {
      setOnSubmitting(false)
      return setSubmitError(err.response.data.err)
    }
    if (res.result !== 'success') {
      setOnSubmitting(false)
      return setSubmitError(res.err)
    }
    onDone()
  }

  return (
    <Box component='div' mb={2} className='add-category'>
      <Box
        component='div'
        display='flex'
        flexDirection='row'
        justifyContent='center'
        height='100%'
        py={2.5}
      >
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          className='font-26 text-dark-blue'
        >
          Create Subcategory
        </Box>
      </Box>
      <div className='i-f-container'>
        <form onSubmit={handleSubmit(submit)} className='i-f-form'>
          <div className='i-f-form-row'>
            <Dropdown
              label='Category'
              name='category'
              value={watch('category')}
              control={control}
              refInput={register({
                required: true
              })}
              items={categories}
            />
            <Input
              label='New Category'
              type='text'
              value={watch('newSubcategory')}
              refInput={register({
                required: true
              })}
              error={errors.newSubcategory}
              name='newSubcategory'
            />
          </div>
          {submitError && <div className='Input-error'>{submitError}</div>}
          <div className='i-f-form-row-submit'>
            <Button
              type='submit'
              className='submit-btn'
              disabled={onSubmitting}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Box>
  )
}

const mapStateToProps = ({ category, store }) => ({
  categories: category.categories,
  subcategories: category.subcategories,
  store: store.store,
})

export default connect(mapStateToProps)(CreateSubcategory)
