import React from 'react'

export default ({ title, value }) => {
  return (
    <div className='report-row py-2'>
      <span>{title}</span>
      <span>{value}</span>
    </div>
  )
}
