import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import './_styles.scss'

const CategoryList = ({
  categories,
  onSelectItem,
  selectedCategoryId,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  useEffect(() => {
    setSelectedIndex(categories.findIndex(c => c._id === selectedCategoryId))
  }, [categories, selectedCategoryId])
  const onSelectCategory = index => {
    if (index !== selectedIndex) {
      setSelectedIndex(index)
      onSelectItem(categories[index])
    } else {
      // unselect category
      setSelectedIndex(-1)
      onSelectItem()
    }
  }
  return (
    <div className='category-filter'>
      <p>Existing Categories</p>
      <div className='category-filter-list'>
        {categories.map((c, i) => (
          <div
            className={`category-filter-item ${i === selectedIndex && 'active'}`}
            onClick={() => onSelectCategory(i)}
            key={c._id}
          >
            {c.label}
          </div>
        ))}
        {/* <div className='add-cate-btn'>
          <Button classes='no-bg' onClick={onAddCategory}>
            <Add /> Add Category
        </Button>
        </div> */}
      </div>
    </div>
  )
}

const mapStateToProps = ({ category }) => ({
  categories: category.categories.sort((a, b) => a.label > b.label ? 1 : -1),
})
export default connect(mapStateToProps)(CategoryList)
