import request from './base'

export const getBanners = () => {
  return request({ url: '/banners' })
}

export const createBanner = ({ image, deepLink }) => {
  return request({ url: '/banners', method: 'POST', data: { image, deepLink } })
}

export const updateBannerStatus = (id, status) => {
  return request({ url: `/banners/${id}/update`, method: 'POST', data: { status } })
}

export const removeBanner = (id) => {
  return request({ url: `/banners/${id}/remove`, method: 'POST' })
}
