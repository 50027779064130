import request from './base'

export const getStores = (filter = {}, page, sort) => {
  return request({
    url: `/stores`,
    params: {
      filter,
      page,
      sort,
    }
  })
}

export const getStore = (storeId) => {
  return request({
    url: `/stores/${storeId}`,
  })
}

export const getReport = (storeId, type = 'day') => {
  return request({
    url: `/stores/${storeId}/report`,
    params: { type }
  })
}

export const updateStore = (storeId, data) => {
  return request({
    method: 'PUT',
    url: `/stores/${storeId}`,
    data,
  })
}
