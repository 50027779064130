import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Add } from '@material-ui/icons'

import Button from 'components/common/button'
import './_styles.scss'

const SubcategoryList = ({
  subcategories: subcategoriesState,
  onAddSubcategory,
  category, // category id
  onSelectItem,
}) => {
  const [subcategories, setSubcategories] = useState([])
  const [selectedIndex, setSelectedIndex] = useState()

  const onSelectSubcategory = index => {
    if (index !== selectedIndex) {
      setSelectedIndex(index)
      onSelectItem(subcategories[index])
    } else {
      // unselect
      setSelectedIndex(-1)
      onSelectItem()
    }
  }
  useEffect(() => {
    setSubcategories(subcategoriesState.filter(s => {
      return s.category === category
    }).sort((a, b) => a.label > b.label ? 1 : -1))
    setSelectedIndex()
  }, [category])
  useEffect(() => {
    setSubcategories(subcategoriesState.filter(s => {
      return s.category === category
    }).sort((a, b) => a.label > b.label ? 1 : -1))
  }, [subcategoriesState])
  return (
    <div>
      <div className='inventory-filter-subcategories'>
        {subcategories.map((c, i) => (
          <div
            className={`inventory-filter-subcategory ${i === selectedIndex && 'active'}`}
            onClick={() => onSelectSubcategory(i)}
          >
            {c.label}
          </div>
        ))}
        <div className='add-cate-btn'>
          <Button classes='no-bg' onClick={onAddSubcategory}>
            <Add /> Add Subcategory
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ category }) => ({
  subcategories: category.subcategories,
})
export default connect(mapStateToProps)(SubcategoryList)
