import React from 'react'
import _debounce from 'lodash/debounce'

import Spinner from 'components/Navigation/Loader/Spinner'
import history from 'utils/history'

class Loader extends React.Component {
  constructor(props) {
    super(props)
    console.log('loader construction')
    this.loader = React.createRef()
    this.animation = this.props.animation || 'fadeOut'
  }

  scrollUp = () => {
    const container = document.querySelector('.container-fluid')
    void (container && (container.scrollTop = 0))
  }

  show = () => {
    this.loader.current.classList.remove('d-none')
  }

  hide = _debounce(() => {
    this.scrollUp()
    this.loader.current.classList.add(this.animation)
    this.loader.current.classList.add('animated')
    setTimeout(() => {
      this.loader.current.classList.add('d-none')
      this.loader.current.classList.remove(this.animation)
      this.loader.current.classList.remove('animated')
    }, 320)
  }, 160)

  animate = () => {
    this.show()
    this.hide()
  }

  componentDidMount () {
    window.addEventListener('DOMContentLoaded', this.show)
    window.addEventListener('load', this.hide)
    this.unlisten = history.listen(() => this.animate())
  }

  componentWillUnmount () {
    window.removeEventListener('DOMContentLoaded', this.show)
    window.removeEventListener('load', this.hide)
    void (this.unlisten && this.unlisten())
  }

  render () {
    return (
      <div className="Loader" ref={this.loader}>
        <Spinner show noBackdrop />
      </div>
    )
  }
}

export default Loader
