// use to initialize state
import { getBuildings } from 'services/buildings'
import { getCategories } from 'services/categories'

import { setUser } from 'reducers/user/actions'
import { setBuildings } from 'reducers/buildings/actions'
import { setCategories, setSubcategories } from 'reducers/category/actions'
export const loadUser = async dispatch => {
  // load user
  let user = localStorage.getItem('user')
  if (user) {
    try {
      user = JSON.parse(user)
    } catch (err) {
      localStorage.removeItem('user')
      return
    }
    // dispatch
    await dispatch(setUser(user))
  }
}

const loadBuildings = async (dispatch) => {
  const buildingsRes = (await getBuildings()).data.data
  dispatch(setBuildings(buildingsRes))
}

export const loadLoggedInData = async dispatch => {
  loadBuildings(dispatch)
  loadCates(dispatch)
}

export const loadCates = async (dispatch) => {
  const { categories, subcategories } = (await getCategories()).data.data
  const sortedCates = categories.map(category => ({
    ...category,
    name: category.label,
    value: category._id,
  })).sort((a, b) => a.label > b.label ? 1 : -1)
  dispatch(setCategories(sortedCates))
  dispatch(setSubcategories(subcategories.map(subcategory => ({
    ...subcategory,
    name: subcategory.label,
    value: subcategory._id,
  }))))
}
